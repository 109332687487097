import DOMPurify from "dompurify";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { INTERNAL } from "../../../IIPconstants";


const BoliSpotLightPage = (props) => {
  const { oktaUser } = props;
  const location = useLocation();
  const link = Object.keys(oktaUser).length === 0 ? "/" : INTERNAL;
  const spotLightData = location.state.boliSpotLightData.data;
  return (
    <>
      <div className="container">
        <div className="row">
          <Link to={link + "/"} className="btn btn-link" data-testid="mg-back">
            <span className="icon-arrow-circle-spin-left" />
            <span>Back To Dashboard</span>
          </Link>
        </div>
        <h2> {spotLightData.mainHeader} </h2>
        <h3> {spotLightData.date} </h3>
        {spotLightData.listOfSections.length !== 0 && spotLightData.listOfSections.map((currentObj, index) => {
          return (
            <div data-testid={`spotlight-announcement-${index+1}`} key={index}>
              <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentObj.header, { ADD_ATTR: ["target"] }) }} />
              <p> {currentObj.description} </p> 
            </div>
          );
        })}
        
        <div className="container row" data-testid="spotlight-moneyrate">
          <h4> {spotLightData.dataHeader && spotLightData.dataHeader} </h4>
          {spotLightData.dataSections.length !== 0 && spotLightData.dataSections.map((currentObj, index) => {
            return (
              <div className="container" key={index}>
                <h4 className="sub-heading-spotlight"> {currentObj.header}
                  <span style={{ display: "block" }}> <h6 className="article sub-heading-spotlight">{currentObj.description}</h6></span>
                </h4>
                <div className="container mm-spacing--minor">
                  <div className="row mm-spacing--minor">
                    {currentObj.dataTiles.map((obj, index) => {
                      return (
                        <div className="col-4 mm-spacing--minor" key={index}>
                          <div className={obj.urlLink === "" ? "text-border" : "text-border-links"}>
                            {
                              obj.urlLink === "" ? 
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj.tileDescription, { ADD_ATTR: ["target"] }) }} /> :
                                <a href={obj.urlLink} rel="noreferrer" target="_blank"> {obj.tileDescription} </a>
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={`${spotLightData.dataSections.length === 0 ? "mm-spacing" : "container mm-spacing"}`} data-testid="spotlight-footer">
          {spotLightData.disclaimers.length !== 0 && spotLightData.disclaimers.map((currentObj, index) => {
            return (
              <div className={`${spotLightData.dataSections.length === 0 ? "" : "container"} ${currentObj.isLink ? "mm-spacing--minor" : ""}`} key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentObj.disclaimerText, { ADD_ATTR: ["target"] }) }} />
            );
          })}
        </div>

      </div>
    </>
    
  );
};

export default BoliSpotLightPage;
