import parse from "html-react-parser";
import React from "react";
import { Link } from "react-router-dom";

const SpotLightBoli = (props) => {
  const { content, path } = props;
  return (
    <div className="row">
      <div className="col-sm-1">
        <picture data-testid="iip-lighhouse">
          <span role="img" title="light house">
            <img alt="lighthouse" src="./lighthouse.svg"></img>
          </span>
        </picture>
      </div>
      <div className="col-sm-11">
        <b data-testid="iip-heading">
          Spotlight on Boli.
        </b>
        {content.dashboard.spotlightBoli.map((currentObj, index) => {
          return (
            <div className="mm-spacing--minor" key={index}>
              <p>{parse(currentObj.spotlightBoliText)}</p>
              <Link 
                to={`${path}${currentObj.spotlightBoliLink}`} 
                state = {{ boliSpotLightData: content.dashboard.spotlightBoli[index] }}
                data-testid={currentObj.spotlightBoliTestData}>
                View Newsletters
              </Link>
            </div>);
        })
        }
      </div>
    </div>
  );
};

export default SpotLightBoli;
